import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Combo from "../mbapp-combobox/mbapp-combobox";
import TextBox from "../mbapp-textbox/mbapp-textbox";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappAguaMedidorForm = ({
  getForm,
  formulario,
}) => {
  const [form, setForm] = useState(formulario);

  // Levantar estado
  getForm(form);

  const handleChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  return (
    <form className="mbapp-form">
      <div className="row">
        <TextBox
          label="Medidor"
          name="numero"
          placeholder="Número de medidor"
          value={form.numero}
          onChange={(val) => handleChange('numero', val)}
        />
      </div>
      <div className="row">
        <TextBox
          label="Padrón"
          name="padron"
          placeholder="Número de padrón del medidor"
          value={form.padron}
          onChange={(val) => handleChange('padron', val)}
        />
      </div>
      <div className="row">
        <TextBox
          label="Titular"
          name="titular"
          placeholder="Apellido y nombre del titular"
          value={form.titular}
          onChange={(val) => handleChange('titular', val)}
        />
      </div>
      <div className="row">
        <TextBox
          label="Dirección"
          name="direccion"
          placeholder="Calle, número, piso, departamento"
          value={form.direccion}
          onChange={(val) => handleChange('direccion', val)}
        />
      </div>
      <div className="row">
        <TextBox
          label="Fecha de instalación"
          name="fechaInstalacion"
          placeholder="Fecha de instalación del medidor"
          value={form.fechaInstalacion}
          onChange={(val) => handleChange('fechaInstalacion', val)}
          type="date"
        />
      </div>
      <div className="row">
        <TextBox
          label="M3"
          name="m3"
          placeholder="Metros cúbicos consumidos"
          value={form.m3}
          onChange={(val) => handleChange('m3', val)}
          type="number"
        />
      </div>
    </form>
  );
};

MbappAguaMedidorForm.propTypes = {
  /** Listado de lecturistas */
  listado: PropTypes.string.isRequired,
  lecturistaAsignado: PropTypes.object.isRequired,
};

MbappAguaMedidorForm.defaultProps = {
  listado: "",
  lecturistaAsignado: {},
};

export default MbappAguaMedidorForm;
