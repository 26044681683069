import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";
import Medidor from "../mbapp-agua-medidores-item/mbapp-agua-medidores-item";
import TextBox from "../mbapp-textbox/mbapp-textbox";
import "../mbapp-button/mbapp-button.css";
import Modal from "../mbapp-modal/mbapp-modal";
import Form from "../mbapp-agua-medidores-form/mbapp-agua-medidores-form";
import SearchBar from "../mbapp-searchbar/mbapp-searchbar";
/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappAguaMedidores = ({ name }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [form, setForm] = useState({});
  const [resultadoBusqueda, setResultadoBusqueda] = useState([]);

  const buscarMedidor = () => {
    setModalVisible(true);
  };

  const crearMedidor = () => {
    setForm({})
    setModalVisible(false)
  };

  return (
    <div className="app">
      <Modal
        isVisible={modalVisible}
        titulo="Nuevo medidor"
        onAccept={() => crearMedidor()}
        onClose={() => setModalVisible(false) && console.log("cerrar")}
        botonAceptarText="Asignar"
      >
        <Form
          formulario={form}
          getForm={(form) => { setForm(form) }}
        />
      </Modal>
      <div className="app--panel app--panel--reclamos">
        <Toolbar label="Medidores">
          <SearchBar placeholder="Buscar medidor" />
          <div
            style={{
              textAlign: "right",
              marginTop: "-5px",
              paddingRight: "var(--sp-mediano)",
            }}
          >
            <button className="btn btn-default btn-primary" onClick={() => setModalVisible(true)}>Agregar</button>
          </div>
        </Toolbar>
        <div className="app--panel--reclamos--container allow-overflow-x">
          <table>
            <tr className="table-header">
              <th>Nº</th>
              <th>Padrón</th>
              <th>Titular</th>
              <th>Dirección</th>
              <th>Localidad</th>
              <th>Barrio</th>
              <th>F. Instalación</th>
              <th>Mín. m³</th>
              <th></th>
            </tr>
              {
                // TODO: Agregar los elementos de la tabla
              }
              {resultadoBusqueda.map((item) => {return(
                <tr>
                </tr>
              )})}
          </table>
        </div>
      </div>
    </div>
  );
};

MbappAguaMedidores.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

MbappAguaMedidores.defaultProps = {
  name: "",
};

export default MbappAguaMedidores;
