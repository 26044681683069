import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";
import Icon from "../mbapp-icons/mbapp-icon";
import axios from "axios";
import { useSnackbar } from "react-simple-snackbar";
import { getUserData, formatoCierre, getNombreMes } from "../../utils/utils.js";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappAguaArchivos = ({ name }) => {
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [listaPeriodos, setListaPeriodos] = useState([]);


  useEffect(() => {
    axios.get(
      `${process.env.REACT_APP_API_AGUA_ENDPOINT}/periodos`,
      {
        headers: {
          Authorization: `Bearer ${getUserData("token")}`,
          appid: "agua",
        },
      }
    ).then((res) => {
      setListaPeriodos(res.data)
    })
  }, [])


  const DownloadFile = (periodo) => {
    axios
      .get(
        `${process.env.REACT_APP_API_AGUA_ENDPOINT}/archivos/generar/${periodo}`,
        {
          headers: {
            Authorization: `Bearer ${getUserData("token")}`,
            appid: "agua",
          },
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `LM${periodo.toString().substring(4)}${periodo
            .toString()
            .substring(0, 4)}.csv`
        );
        document.body.appendChild(link);
        link.click();
        closeSnackbar();
        openSnackbar("💾 ¡Archivo descargado!");
      })
      .catch((err) => {
        openSnackbar(
          "⚠️ Hubo un problema al guardar el registro: " + err.message
        );
      });
  };
  return (
    <div className="app">
      <div className="app--panel app--panel--reclamos">
        <Toolbar label="Procesar archivos de tasas" />
        <div className="app--panel--container">
          <table className="text-left">
            <tr className="table-header">
              <th>Mes</th>
              <th>Año</th>
              <th>F. proceso</th>
              <th className="text-center">Tipo</th>
            </tr>

            {listaPeriodos.map((periodo) => {
              return (
                <tr>
                  <td>{getNombreMes(periodo.mes)}</td>
                  <td>{periodo.anio}</td>
                  <td>{formatoCierre(periodo.fechaCierre)}</td>
                  <td className="text-center">
                    <button
                      className="btn action-btn"
                      onClick={() => DownloadFile(periodo.periodo)}
                    >
                      <Icon name="download" size={16} />
                    </button>
                  </td>
                </tr>
              )
            })}
          </table>
        </div>
      </div>
    </div>
  );
};

MbappAguaArchivos.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

MbappAguaArchivos.defaultProps = {
  name: "",
};

export default MbappAguaArchivos;
